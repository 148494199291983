<template>
  <footer class="mt-4">
    <div class="container">
      <div class="row">
        <div class="col-12 footer-body">
          <div>
            <router-link to="/">
            <img
              class="logo"
              alt="Logo TSA Safety Services"
              src="/img/logo.png"
            />
            </router-link>
          </div>
          <div>
            <strong>
              &copy; {{ new Date() | moment('YYYY') }} TSA Safety Services
            </strong>
          </div>
        </div>
      </div>
    </div>
    <cookie-law theme="westvlaanderen" @accept="acceptCookies()">
      <div slot-scope="props" class="w-100">
        <div class="container-fluid pt-4 pb-3">
          <div class="row">
            <div class="col col-sm-12 col-lg-8">
              <p>
                Deze website gebruikt cookies om je beter te kunnen helpen en de
                website te verbeteren. Ga je akkoord met de cookies?
                <a href="https://www.tsa-bv.nl/faq/disclaimer.htm">Meer info</a>
              </p>
            </div>
            <div class="col col-md-12 col-lg-4 text-center text-lg-right">
              <a
                class="btn btn-outline-light mr-3 mb-lg-3 mb-xl-0"
                href="https://www.tsa-bv.nl/faq/disclaimer.htm"
                target="_BLANK"
                >Meer info</a
              >
              <button
                class="mb-lg-3 mb-xl-0 btn btn-outline-light"
                @click="props.accept"
              >
                <span>Ik ga akkoord</span>
              </button>
              <button class="ml-3 btn btn-outline-light" @click="props.decline">
                <span>Niet toestaan</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </cookie-law>
  </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
  components: { CookieLaw },
  methods: {
    acceptCookies: function() {
      this.$store.commit('acceptCookies')
    }
  }
}
</script>
<style scoped lang="scss">
footer {
  background-color: white;
  padding: 20px 0;
  color: #1b1b1b;
  .footer-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .icon-link {
    a {
      color: #6c757d;
      font-size: 20px;
    }
  }
  font-family: 'Roboto';
  .footer-xl {
    display: inline-block;
  }
  .footer-lg,
  .footer-xs {
    display: none;
  }
  li {
    display: inline-flex;
    vertical-align: middle;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
  }
  .logo-title-in-one {
    display: none;
  }
  .seperator {
    height: 21px;
    width: 1px;
    background-color: #282828;
  }
  .logo {
    width: 86px;
    height: 36px;
  }
}
@media (max-width: 1200px) {
  footer {
    text-align: center;
    .footer-xl {
      display: none;
    }
    .footer-lg {
      display: block;
    }
  }
}
@media (max-width: 991px) {
  footer {
    .footer-xl {
      display: none;
    }
    .footer-lg {
      display: block;
    }
    .pl-4,
    .pr-3 {
      padding: 0 !important;
    }
    ul {
      li {
        width: 100% !important;
        text-align: center;
        margin: auto;
        display: block;
        margin-top: 10px;
      }
    }
  }
}
@media (max-width: 523px) {
  .footer-lg {
    display: none !important;
  }
  .footer-sm {
    display: inline-block !important;
  }
}
</style>
