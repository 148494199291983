import Vue from 'vue'
import VueRouter from 'vue-router'
//import Index from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Login/Index.vue')
  },
  {
    path: '/new-sub',
    name: 'newSub',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Account/Contact/NewSub.vue')
  },
  {
    path: '/edit-sub',
    name: 'editSub',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Account/Contact/EditSub.vue')
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Register/Index.vue')
  },
  {
    path: '/reservation/:reservationID',
    name: 'reservation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Reservation/Reservation.vue')
  },
  {
    path: '/account',
    name: 'account',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () => import('../views/Account/Index.vue'),
      account: () => import('../views/Account/User/Index.vue')
    },
    children: [
      {
        path: '/account/user',
        name: 'account-user',
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/User/Index.vue')
        }
      },
      {
        path: '/account/service',
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/Service/Index.vue')
        }
      },
      {
        path: '/account/serial',
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/Serial/Index.vue')
        }
      },
      {
        path: '/account/reservation',
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/Reservation/Index.vue')
        }
      },
      {
        path: '/account/organisation',
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/Organisation/Index.vue')
        }
      },
      {
        path: '/account/item',
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/Item/Index.vue')
        }
      },
      {
        path: '/account/customer-serial',
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/CustomerSerial/Index.vue')
        }
      },
      {
        path: '/account/customer-item',
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/CustomerItem/Index.vue')
        }
      },
      {
        path: '/account/contact',
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/Contact/Index.vue')
        }
      }
    ]
  },
  {
    path: '/profiel-keuze',
    name: 'profileSelector',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/ProfileSelector.vue')
  },
  {
    path: '/wachtwoord-herstellen/:code',
    name: 'newPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/ResetPassword.vue')
  },
  {
    path: '/s/:serialid',
    name: 'serial',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Serial/Index.vue')
  },
  {
    path: '/s/:serialid/:customerid',
    name: 'customer-serial',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/CustomerSerial/Index.vue')
  },
  {
    path: '/wachtwoord-herstellen/',
    name: 'newPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/ResetPassword.vue')
  },
  {
    path: '/wachtwoord-herstellen',
    name: 'forgotPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/ForgotPassword.vue')
  },
  {
    path: '/account-aangemaakt',
    name: 'accountCreated',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Register/AccountCreated.vue')
  },
  {
    path: '/account-aangemaakt/:code',
    name: 'accountCreated',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Register/AccountCreated.vue')
  },
  {
    path: '/account-email-verificatie',
    name: 'accountEmailSend',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Register/AccountEmailSend.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})

export default router
