import i18n from '../locales'

const axios = require('axios')
import store from '../state/store'
import router from '../router/index'

let axiosCreate = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/v2`,
  headers: {
    //Authorization: `Bearer ${store.state.token}`
  },
  validateStatus: function(status) {
    return status // Resolve only if the status code is less than 500
  }
})

axiosCreate.interceptors.request.use(request => {
  request.headers['accept-language'] = i18n.locale
  return request
})

axiosCreate.interceptors.response.use(response => {
  if (
    !response.config.url.includes('registerUser') &&
    (response.status === 401 || response.status === 404)
  ) {
    logout()
  }

  return response
})

function logout() {
  store.commit('unsetUser')
  store.commit('unsetCart')

  router.push({ name: 'index', query: { sessionExpired: true } })
}

module.exports = { axiosCreate }
