const { axiosCreate } = require('./Api')
const moment = require('moment')
const { checkResponse } = require('../controllers/errorHandler')

import i18n from '@/locales/index'
import store from '../state/store'

async function getCart({ warehouse } = {}) {
  if (store.state.activeWarehouse === '') return
  const result = await axiosCreate.get(`shop/cart`, {
    headers: { authorization: store.getters.token },
    params: {
      ContactID: store.getters.contactID,
      sorton: 'ranking',
      warehouse: warehouse
    }
  })

  if ((await checkResponse(result)) !== null) return

  if (result.data) {
    store.commit('setCart', {
      cart: result.data
    })
  }

  store.commit('setDateTimeExpectedStart', {
    date: moment(result.data.DateTimeExpectedStart, 'YYYY-MM-DD').format(
      'DD/MM/YYYY'
    )
  })

  store.commit('setDateTimeExpectedEnd', {
    date: moment(result.data.DateTimeExpectedEnd, 'YYYY-MM-DD').format(
      'DD/MM/YYYY'
    )
  })

  store.commit('setDateTimeBusinessStart', {
    date: moment(result.data.DateTimeExpectedStart, 'YYYY-MM-DD').format(
      'DD/MM/YYYY'
    )
  })

  store.commit('setDateTimeBusinessEnd', {
    date: moment(result.data.DateTimeExpectedEnd, 'YYYY-MM-DD').format(
      'DD/MM/YYYY'
    )
  })

  store.commit('setActiveWarehouseID', {
    warehouseID: result.data.WarehouseID
  })

  let cartItems = []

  if (result.data.CartItems && result.data.CartItems.Collection) {
    result.data.CartItems.Collection.forEach(cartItem => {
      cartItems.push({
        item: cartItem.Description,
        cartItemID: cartItem.CartItemID,
        price: cartItem.PriceIncVAT,
        amount: cartItem.Amount || 0,
        delete: cartItem.CartItemID,
        itemType: cartItem.ItemType,
        total: cartItem.PriceIncVAT,
        maxAdded: cartItem.MaxPerCustomerLeftover,
        maxPerCustomer: cartItem.MaxPerCustomer
      })
    })

    cartItems = cartItems.filter(function(obj) {
      return obj.item !== 'Administratiekosten'
    })
  }

  return cartItems
}

async function getBusinessHours({ start, end, warehouseID }) {
  const result = await axiosCreate.get(`shop/businessdays/BusinessHours`, {
    params: {
      contactID: store.getters.contactID,
      WarehouseID: warehouseID,
      Start: moment(start, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      End: moment(end, 'DD-MM-YYYY').format('YYYY-MM-DD')
    },
    headers: { authorization: store.getters.token }
  })

  if ((await checkResponse(result)) !== null) return

  let startHours = []
  let endHours = []

  for (let hourObject of result.data.BusinessHourStart) {
    startHours.push({
      value: hourObject.BusinessHour,
      text: hourObject.BusinessHour
    })
  }

  for (let hourObject of result.data.BusinessHourEnd) {
    endHours.push({
      value: hourObject.BusinessHour,
      text: hourObject.BusinessHour
    })
  }

  const hours = {
    startHours,
    endHours
  }

  return hours
}

async function checkout({ notes, CUST_ActivityDescription }) {
  const result = await axiosCreate.post(
    `shop/cart/checkout`,
    { notes, CUST_ActivityDescription },
    {
      params: {
        ContactID: store.getters.contactID
      },
      headers: { authorization: store.getters.token }
    }
  )

  if ((await checkResponse(result)) !== null) return

  return result
}

async function addToCartSimple({ itemID, compositionID } = {}) {
  await checkCartAge()
  const result = await axiosCreate.post(
    `custcart/cartItems`,

    {
      ItemID: itemID,
      ContactID: store.getters.contactID,
      CompositionID: compositionID
    },

    { headers: { authorization: store.getters.token } }
  )

  if ((await checkResponse(result)) !== null) return

  return result
}

async function addToCart({
  warehouseID,
  dateTimeBusinessStart,
  dateTimeBusinessEnd,
  itemID,
  compositionID,
  amount
} = {}) {
  await checkCartAge()
  const result = await axiosCreate.post(
    `shop/cart/cartitems`,
    [
      {
        WarehouseID: warehouseID,
        DateTimeBusinessStart: moment(
          dateTimeBusinessStart,
          'DD/MM/YYYY'
        ).format('YYYY-MM-DD'),
        DateTimeBusinessEnd: moment(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        ),
        DateTimeExpectedStart: moment(
          dateTimeBusinessStart,
          'DD/MM/YYYY'
        ).format('YYYY-MM-DD'),
        DateTimeExpectedEnd: moment(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        ),
        ItemID: itemID,
        CompositionID: compositionID,
        Amount: amount
      }
    ],
    {
      headers: { authorization: store.getters.token },
      params: {
        ContactID: store.getters.contactID
      }
    }
  )

  if ((await checkResponse(result)) !== null) return

  return result
}

// Check if cart cache is older then a day. Carts expire at 12 AM
async function checkCartAge() {
  const cartCache = store.getters.cartCache
  const cartDate = moment(cartCache.DateTimeCreated, 'YYYY-MM-DD')
  if (moment(cartDate).isBefore(moment().format('YYYY-MM-DD'), 'day')) {
    await getCart()
  }
}

async function updateCartItem({ cartItemID, amount }) {
  try {
    const result = await axiosCreate.patch(
      `shop/cart/cartitems/${cartItemID}`,
      {
        CartItemID: cartItemID,
        Amount: amount
      },
      {
        headers: { authorization: store.getters.token },
        params: {
          ContactID: store.getters.contactID
        }
      }
    )

    if ((await checkResponse(result)) !== null) return

    return result
  } catch (error) {
    if ((await checkResponse(error)) !== null) return
    return false
  }
}

async function updateCart({
  warehouseID,
  returnWarehouseID,
  businessHourStart,
  businessHourEnd,
  dateTimeBusinessStart,
  dateTimeBusinessEnd
} = {}) {
  const DateTimeExpectedStart = moment(
    dateTimeBusinessStart,
    'DD/MM/YYYY'
  ).format('YYYY-MM-DD')
  const DateTimeExpectedEnd = moment(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
    'YYYY-MM-DD'
  )
  const DateTimeBusinessStart = moment(
    dateTimeBusinessStart,
    'DD/MM/YYYY'
  ).format('YYYY-MM-DD')
  const DateTimeBusinessEnd = moment(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
    'YYYY-MM-DD'
  )

  const result = await axiosCreate.patch(
    `shop/cart`,
    {
      WarehouseID: warehouseID,
      ReturnWarehouseID: returnWarehouseID,
      BusinessHourStart: businessHourStart,
      BusinessHourEnd: businessHourEnd,
      DateTimeExpectedStart,
      DateTimeExpectedEnd,
      DateTimeBusinessStart,
      DateTimeBusinessEnd
    },
    {
      headers: { authorization: store.getters.token },
      params: {
        ContactID: store.getters.contactID
      }
    }
  )

  if ((await checkResponse(result)) !== null) return
  if (
    DateTimeExpectedStart !==
      moment(result.data.DateTimeExpectedStart).format('YYYY-MM-DD') ||
    DateTimeExpectedEnd !==
      moment(result.data.DateTimeExpectedEnd).format('YYYY-MM-DD') ||
    DateTimeBusinessStart !==
      moment(result.data.DateTimeBusinessStart).format('YYYY-MM-DD') ||
    DateTimeBusinessEnd !==
      moment(result.data.DateTimeBusinessEnd).format('YYYY-MM-DD')
  ) {
    store.commit('sendToast', {
      toastMessage: i18n.t('updated-reservation-period')
    })
  }

  if (result.data) {
    store.commit('setCart', {
      cart: result.data
    })
  }

  return result
}

async function deleteCartItem({ cartItemID }) {
  const result = await axiosCreate.delete(`shop/cart/cartitems/${cartItemID}`, {
    headers: { authorization: store.getters.token },
    params: {
      ContactID: store.getters.contactID
    }
  })

  if ((await checkResponse(result)) !== null) return

  return result
}

async function copyCart() {
  const result = axiosCreate.get(
    `shop/cart/copy/${store.state.anonymousToken.replace('Bearer ', '')}`,
    {
      headers: { authorization: store.getters.token },
      params: {
        ContactID: store.getters.contactID
      }
    }
  )
  return result
}

async function getSuggestedCartItems() {
  const result = await axiosCreate.get(`shop/cart/custinsurances`, {
    headers: { authorization: store.getters.token },
    params: {
      ContactID: store.getters.contactID
    }
  })
  return result.data.Collection
}

async function cartMutationCheck({
  warehouseID,
  dateTimeBusinessStart,
  dateTimeBusinessEnd
} = {}) {
  const result = await axiosCreate.get(`shop/cart/invalidCartItems`, {
    params: {
      ContactID: store.getters.contactID,
      warehouseID,
      dateTimeBusinessStart: moment(dateTimeBusinessStart, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      dateTimeBusinessEnd: moment(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      dateTimeExpectedStart: moment(dateTimeBusinessStart, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      dateTimeExpectedEnd: moment(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      )
    },
    headers: { authorization: store.getters.token }
  })
  if ((await checkResponse(result)) !== null) return
  return result.data
}

module.exports = {
  addToCart,
  addToCartSimple,
  copyCart,
  checkout,
  cartMutationCheck,
  deleteCartItem,
  getCart,
  getSuggestedCartItems,
  getBusinessHours,
  updateCartItem,
  updateCart
}
