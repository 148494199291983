import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import { getCart } from '../services/CartService'
import router from '../router/index'

const merge = require('lodash.merge')

const vuexPersist = new VuexPersist({
  key: 'rentmagic-frontend',
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    user: {},
    bearerToken: null,
    DateTimeBusinessStart: '',
    DateTimeBusinessEnd: '',
    DateTimeExpectedStart: '',
    DateTimeExpectedEnd: '',
    cartCount: 0,
    cart: [],
    customer: {},
    contactID: null,
    accounts: [],
    account: {},
    reservation: {},
    customerID: null,
    searchTerm: null,
    gridListing: false,
    reservations: null,
    subUsers: [],
    role: '',
    itemStore: {},
    acceptedCookies: false
  },
  mutations: {
    acceptCookies(state) {
      state.acceptedCookies = true
    },
    setUser(state, { user }) {
      state.user = user
      state.contactID = state.user.Accounts.Collection[0].ContactID
      state.accounts = state.user.Accounts.Collection
      state.account = state.accounts.find(
        account => account.ContactID == state.contactID
      )
      state.customerID = state.account.CustomerID
      state.customer = state.user.Contacts.find(contact => contact.ContactID === state.contactID).Customer

      state.role = state.user.RoleName
    },
    setAccounts(state, { accounts }) {
      state.accounts = accounts
      state.account = state.accounts.find(
        account => account.UserEmail == state.user.UserEmail
      )
    },
    updateUser(state, { user }) {
      state.user = merge(state.user, user)
    },
    updateAccount(state, { account }) {
      state.user = merge(state.account, account)
    },
    toggleGridListing(state) {
      if (state.gridListing) {
        state.gridListing = false
      } else {
        state.gridListing = true
      }
    },
    addItemStoreItem(state, { item, key }) {
      state.itemStore[key] = item
    },
    resetStoreItem(state, { key }) {
      delete state.itemStore[key]
    },
    resetStoreItems(state) {
      state.itemStore = {}
    },
    setContactID(state, { contactID }) {
      state.contactID = contactID
      state.account = state.accounts.find(
        account => account.ContactID == state.contactID
      )
      state.customerID = state.account.CustomerID
      state.customer = state.user.Contacts.find(contact => contact.ContactID === state.contactID).Customer
      getCart()
    },
    setSearchTerm(state, { searchTerm }) {
      state.searchTerm = searchTerm
    },
    unsetUser(state, { reason = null } = {}) {
      state.user = {}
      state.bearerToken = null
      state.contactID = null
      state.accounts = []
      state.account = null
      state.reservations = null
      state.subUsers = []
      state.customerID = null
      state.customeriD = null
      state.role = null
      state.retrievalTime = ''
      state.returnTime = ''
      state.customer = {}

      if (reason) {
        router.push(`/?${reason}`)
        return
      }

      router.push('/')
    },
    setLatestReservation(state, { reservation }) {
      state.reservation = reservation
    },
    setCart(state, { cart }) {
      state.cartCache = cart
      state.cartCount = cart.CartItems.Collection.length
    },
    unsetCart(state) {
      state.cart = []
      state.cartCount = 0
    },
    setBearerToken(state, { token }) {
      state.bearerToken = token
    },
    unsetBearerToken(state) {
      state.bearerToken = ''
    },
    setDateTimeBusinessStart(state, payload) {
      state.DateTimeBusinessStart = payload.date
    },
    unsetDateTimeBusinessStart(state) {
      state.DateTimeBusinessStart = ''
    },
    setDateTimeBusinessEnd(state, payload) {
      state.DateTimeBusinessEnd = payload.date
    },
    unsetDateTimeBusinessEnd(state) {
      state.DateTimeBusinessEnd = ''
    },
    setDateTimeExpectedStart(state, payload) {
      state.DateTimeExpectedStart = payload.date
    },
    unsetDateTimeExpectedStart(state) {
      state.DateTimeExpectedStart = ''
    },
    setDateTimeExpectedEnd(state, payload) {
      state.DateTimeExpectedEnd = payload.date
    },
    unsetDateTimeExpectedEnd(state) {
      state.DateTimeExpectedEnd = ''
    },
    setSubUsers(state, { subUsers }) {
      state.subUsers = subUsers
    },
    setReservations(state, { reservations }) {
      state.reservations = reservations
    }
  },
  getters: {
    cartCache: state => state.cartCache,
    user: state => state.user,
    token: state => state.bearerToken,
    cartCount: state => state.cartCount,
    DateTimeBusinessStart: state => state.DateTimeBusinessStart,
    DateTimeBusinessEnd: state => state.DateTimeBusinessEnd,
    DateTimeExpectedStart: state => state.DateTimeExpectedStart,
    DateTimeExpectedEnd: state => state.DateTimeExpectedEnd,
    contactID: state => state.contactID,
    accounts: state => state.accounts,
    account: state => state.account,
    reservation: state => state.reservation,
    customerID: state => state.customerID,
    searchTerm: state => state.searchTerm,
    gridListing: state => state.gridListing,
    subUsers: state => state.subUsers,
    getItemStoreItem: state => key => {
      return state.itemStore[key]
    },
    getAcceptedCookiesState: state => state.acceptedCookies
  },
  plugins: [vuexPersist.plugin]
})
