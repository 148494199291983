<template>
  <header class="mb-3">
    <div class="container">
      <div class="row" align-v="center">
        <div class="col col-xs-12 col-md-5 text-center text-md-left">
          <router-link to="/">
            <img
              alt="Logo TSA Safety Services"
              style="height: 60px"
              src="/img/logo.png"
            />
          </router-link>
        </div>
        <div class="col col-sm-12 col-md-7">
          <form class="header-buttons">
            <router-link v-if="user.UserName" to="/account/user">
              <button class="btn btn-primary mr-3 pl-3 pr-3 mb-2 mb-md-0">
                <font-awesome-icon icon="user-circle" />&nbsp;Mijn account
              </button>
            </router-link>
            <button
              v-if="user.UserName"
              class="btn btn-secondary mr-3 pl-3 pr-3 mb-2 mb-md-0"
              @click="logout"
            >
              <font-awesome-icon icon="sign-out-alt" />&nbsp;Uitloggen
            </button>
          </form>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: ''
    }
  },
  computed: {
    user: function() {
      return this.$store.getters.user
    }
  },
  methods: {
    logout: async function() {
      await this.$store.commit('unsetCart')
      await this.$store.commit('unsetUser', {
        reason: 'loggedOut=true'
      })

      //this.$router.push({ name: 'index' })
    },
    search: async function(event) {
      event.preventDefault()
      if (this.searchTerm.length > 1) {
        await this.$router.push({
          name: 'search',
          params: { searchTerm: this.searchTerm.toLowerCase() }
        })
        this.searchTerm = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.header-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;
}
header {
  background-color: white;
  border-top: 1px solid #e3e3e4;
  border-bottom: 1px solid #e3e3e4;
  -webkit-box-shadow: 0 2px 4px 0 #0000001a;
  box-shadow: 0 2px 4px 0 #0000001a;
  padding-top: 10px;
  padding-bottom: 10px;
}
.container {
  background-color: transparent;
}
.logo {
  height: 50px;
  margin-top: 10px;
}
.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 0;
  font-size: 0.875rem;
  > .container,
  > .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .topbar-text,
  .topbar-link {
    display: inline-block;
    margin-bottom: 0;
    text-decoration: none !important;
    > i {
      margin-right: 0.375rem;
      font-size: 1.15em;
      vertical-align: middle;
    }
  }
  .header-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  header {
    background-color: white;
    border-top: 1px solid #e3e3e4;
    border-bottom: 1px solid #e3e3e4;
    -webkit-box-shadow: 0 2px 4px 0 #0000001a;
    box-shadow: 0 2px 4px 0 #0000001a;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .container {
    background-color: transparent;
  }
  .logo {
    height: 50px;
    margin-top: 10px;
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 0;
    font-size: 0.875rem;
    > .container,
    > .container-fluid {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .topbar-text,
    .topbar-link {
      display: inline-block;
      margin-bottom: 0;
      text-decoration: none !important;
      > i {
        margin-right: 0.375rem;
        font-size: 1.15em;
        vertical-align: middle;
      }
    }
    .header-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      padding-top: 10px;
    }
    header {
      background-color: white;
      border-top: 1px solid #e3e3e4;
      border-bottom: 1px solid #e3e3e4;
      -webkit-box-shadow: 0 2px 4px 0 #0000001a;
      box-shadow: 0 2px 4px 0 #0000001a;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .container {
      background-color: transparent;
    }
    .logo {
      height: 50px;
      margin-top: 10px;
    }
    .topbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.625rem 0;
      font-size: 0.875rem;
      > .container,
      > .container-fluid {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .topbar-text,
      .topbar-link {
        display: inline-block;
        margin-bottom: 0;
        text-decoration: none !important;
        > i {
          margin-right: 0.375rem;
          font-size: 1.15em;
          vertical-align: middle;
        }
      }
      .header-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;
        padding-top: 10px;
      }
      header {
        background-color: white;
        border-top: 1px solid #e3e3e4;
        border-bottom: 1px solid #e3e3e4;
        -webkit-box-shadow: 0 2px 4px 0 #0000001a;
        box-shadow: 0 2px 4px 0 #0000001a;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .container {
        background-color: transparent;
      }
      .logo {
        height: 50px;
        margin-top: 10px;
      }
      .topbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.625rem 0;
        font-size: 0.875rem;
        > .container,
        > .container-fluid {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .topbar-text,
        .topbar-link {
          display: inline-block;
          margin-bottom: 0;
          text-decoration: none !important;
          > i {
            margin-right: 0.375rem;
            font-size: 1.15em;
            vertical-align: middle;
          }
        }
        .header-buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 10px;
          padding-top: 10px;
        }
        header {
          background-color: white;
          border-top: 1px solid #e3e3e4;
          border-bottom: 1px solid #e3e3e4;
          -webkit-box-shadow: 0 2px 4px 0 #0000001a;
          box-shadow: 0 2px 4px 0 #0000001a;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .container {
          background-color: transparent;
        }
        .logo {
          height: 50px;
          margin-top: 10px;
        }
        .topbar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.625rem 0;
          font-size: 0.875rem;
          > .container,
          > .container-fluid {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .topbar-text,
          .topbar-link {
            display: inline-block;
            margin-bottom: 0;
            text-decoration: none !important;
            > i {
              margin-right: 0.375rem;
              font-size: 1.15em;
              vertical-align: middle;
            }
          }
          .header-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 10px;
            padding-top: 10px;
          }
          header {
            background-color: white;
            border-top: 1px solid #e3e3e4;
            border-bottom: 1px solid #e3e3e4;
            -webkit-box-shadow: 0 2px 4px 0 #0000001a;
            box-shadow: 0 2px 4px 0 #0000001a;
            padding-top: 10px;
            padding-bottom: 10px;
          }
          .container {
            background-color: transparent;
          }
          .logo {
            height: 50px;
            margin-top: 10px;
          }
          .topbar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.625rem 0;
            font-size: 0.875rem;
            > .container,
            > .container-fluid {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            .topbar-text,
            .topbar-link {
              display: inline-block;
              margin-bottom: 0;
              text-decoration: none !important;
              > i {
                margin-right: 0.375rem;
                font-size: 1.15em;
                vertical-align: middle;
              }
            }
            .header-buttons {
              display: flex;
              align-items: center;
              justify-content: center;
              padding-bottom: 10px;
              padding-top: 10px;
            }
            header {
              background-color: white;
              border-top: 1px solid #e3e3e4;
              border-bottom: 1px solid #e3e3e4;
              -webkit-box-shadow: 0 2px 4px 0 #0000001a;
              box-shadow: 0 2px 4px 0 #0000001a;
              padding-top: 10px;
              padding-bottom: 10px;
            }
            .container {
              background-color: transparent;
            }
            .logo {
              height: 50px;
              margin-top: 10px;
            }
            .topbar {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0.625rem 0;
              font-size: 0.875rem;
              > .container,
              > .container-fluid {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
              .topbar-text,
              .topbar-link {
                display: inline-block;
                margin-bottom: 0;
                text-decoration: none !important;
                > i {
                  margin-right: 0.375rem;
                  font-size: 1.15em;
                  vertical-align: middle;
                }
              }
              .header-buttons {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 10px;
                padding-top: 10px;
              }
              header {
                background-color: white;
                border-top: 1px solid #e3e3e4;
                border-bottom: 1px solid #e3e3e4;
                -webkit-box-shadow: 0 2px 4px 0 #0000001a;
                box-shadow: 0 2px 4px 0 #0000001a;
                padding-top: 10px;
                padding-bottom: 10px;
              }
              .container {
                background-color: transparent;
              }
              .logo {
                height: 50px;
                margin-top: 10px;
              }
              .topbar {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.625rem 0;
                font-size: 0.875rem;
                > .container,
                > .container-fluid {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                }
                .topbar-text,
                .topbar-link {
                  display: inline-block;
                  margin-bottom: 0;
                  text-decoration: none !important;
                  > i {
                    margin-right: 0.375rem;
                    font-size: 1.15em;
                    vertical-align: middle;
                  }
                }
                .header-buttons {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding-bottom: 10px;
                  padding-top: 10px;
                }
                header {
                  background-color: white;
                  border-top: 1px solid #e3e3e4;
                  border-bottom: 1px solid #e3e3e4;
                  -webkit-box-shadow: 0 2px 4px 0 #0000001a;
                  box-shadow: 0 2px 4px 0 #0000001a;
                  padding-top: 10px;
                  padding-bottom: 10px;
                }
                .container {
                  background-color: transparent;
                }
                .logo {
                  height: 50px;
                  margin-top: 10px;
                }
                .topbar {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0.625rem 0;
                  font-size: 0.875rem;
                  > .container,
                  > .container-fluid {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  }
                  .topbar-text,
                  .topbar-link {
                    display: inline-block;
                    margin-bottom: 0;
                    text-decoration: none !important;
                    > i {
                      margin-right: 0.375rem;
                      font-size: 1.15em;
                      vertical-align: middle;
                    }
                  }
                  .header-buttons {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 10px;
                    padding-top: 10px;
                  }
                  header {
                    background-color: white;
                    border-top: 1px solid #e3e3e4;
                    border-bottom: 1px solid #e3e3e4;
                    -webkit-box-shadow: 0 2px 4px 0 #0000001a;
                    box-shadow: 0 2px 4px 0 #0000001a;
                    padding-top: 10px;
                    padding-bottom: 10px;
                  }
                  .container {
                    background-color: transparent;
                  }
                  .logo {
                    height: 50px;
                    margin-top: 10px;
                  }
                  .topbar {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0.625rem 0;
                    font-size: 0.875rem;
                    > .container,
                    > .container-fluid {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    }
                    .topbar-text,
                    .topbar-link {
                      display: inline-block;
                      margin-bottom: 0;
                      text-decoration: none !important;
                      > i {
                        margin-right: 0.375rem;
                        font-size: 1.15em;
                        vertical-align: middle;
                      }
                    }
                    .header-buttons {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      padding-bottom: 10px;
                      padding-top: 10px;
                    }
                    header {
                      background-color: white;
                      border-top: 1px solid #e3e3e4;
                      border-bottom: 1px solid #e3e3e4;
                      -webkit-box-shadow: 0 2px 4px 0 #0000001a;
                      box-shadow: 0 2px 4px 0 #0000001a;
                      padding-top: 10px;
                      padding-bottom: 10px;
                    }
                    .container {
                      background-color: transparent;
                    }
                    .logo {
                      height: 50px;
                      margin-top: 10px;
                    }
                    .topbar {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      padding: 0.625rem 0;
                      font-size: 0.875rem;
                      > .container,
                      > .container-fluid {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      }
                      .topbar-text,
                      .topbar-link {
                        display: inline-block;
                        margin-bottom: 0;
                        text-decoration: none !important;
                        > i {
                          margin-right: 0.375rem;
                          font-size: 1.15em;
                          vertical-align: middle;
                        }
                      }
                      .header-buttons {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-bottom: 10px;
                        padding-top: 10px;
                      }
                      header {
                        background-color: white;
                        border-top: 1px solid #e3e3e4;
                        border-bottom: 1px solid #e3e3e4;
                        -webkit-box-shadow: 0 2px 4px 0 #0000001a;
                        box-shadow: 0 2px 4px 0 #0000001a;
                        padding-top: 10px;
                        padding-bottom: 10px;
                      }
                      .container {
                        background-color: transparent;
                      }
                      .logo {
                        height: 50px;
                        margin-top: 10px;
                      }
                      .topbar {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0.625rem 0;
                        font-size: 0.875rem;
                        > .container,
                        > .container-fluid {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        }
                        .topbar-text,
                        .topbar-link {
                          display: inline-block;
                          margin-bottom: 0;
                          text-decoration: none !important;
                          > i {
                            margin-right: 0.375rem;
                            font-size: 1.15em;
                            vertical-align: middle;
                          }
                        }
                        .header-buttons {
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          padding-bottom: 10px;
                          padding-top: 10px;
                        }
                        header {
                          background-color: white;
                          border-top: 1px solid #e3e3e4;
                          border-bottom: 1px solid #e3e3e4;
                          -webkit-box-shadow: 0 2px 4px 0 #0000001a;
                          box-shadow: 0 2px 4px 0 #0000001a;
                          padding-top: 10px;
                          padding-bottom: 10px;
                        }
                        .container {
                          background-color: transparent;
                        }
                        .logo {
                          height: 50px;
                          margin-top: 10px;
                        }
                        .topbar {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          padding: 0.625rem 0;
                          font-size: 0.875rem;
                          > .container,
                          > .container-fluid {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                          }
                          .topbar-text,
                          .topbar-link {
                            display: inline-block;
                            margin-bottom: 0;
                            text-decoration: none !important;
                            > i {
                              margin-right: 0.375rem;
                              font-size: 1.15em;
                              vertical-align: middle;
                            }
                          }
                          .header-buttons {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding-bottom: 10px;
                            padding-top: 10px;
                          }
                          header {
                            background-color: white;
                            border-top: 1px solid #e3e3e4;
                            border-bottom: 1px solid #e3e3e4;
                            -webkit-box-shadow: 0 2px 4px 0 #0000001a;
                            box-shadow: 0 2px 4px 0 #0000001a;
                            padding-top: 10px;
                            padding-bottom: 10px;
                          }
                          .container {
                            background-color: transparent;
                          }
                          .logo {
                            height: 50px;
                            margin-top: 10px;
                          }
                          .topbar {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 0.625rem 0;
                            font-size: 0.875rem;
                            > .container,
                            > .container-fluid {
                              display: flex;
                              align-items: center;
                              justify-content: space-between;
                            }
                            .topbar-text,
                            .topbar-link {
                              display: inline-block;
                              margin-bottom: 0;
                              text-decoration: none !important;
                              > i {
                                margin-right: 0.375rem;
                                font-size: 1.15em;
                                vertical-align: middle;
                              }
                            }
                            .header-buttons {
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              padding-bottom: 10px;
                              padding-top: 10px;
                            }
                            header {
                              background-color: white;
                              border-top: 1px solid #e3e3e4;
                              border-bottom: 1px solid #e3e3e4;
                              -webkit-box-shadow: 0 2px 4px 0 #0000001a;
                              box-shadow: 0 2px 4px 0 #0000001a;
                              padding-top: 10px;
                              padding-bottom: 10px;
                            }
                            .container {
                              background-color: transparent;
                            }
                            .logo {
                              height: 50px;
                              margin-top: 10px;
                            }
                            .topbar {
                              display: flex;
                              align-items: center;
                              justify-content: space-between;
                              padding: 0.625rem 0;
                              font-size: 0.875rem;
                              > .container,
                              > .container-fluid {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                              }
                              .topbar-text,
                              .topbar-link {
                                display: inline-block;
                                margin-bottom: 0;
                                text-decoration: none !important;
                                > i {
                                  margin-right: 0.375rem;
                                  font-size: 1.15em;
                                  vertical-align: middle;
                                }
                              }
                              .header-buttons {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding-bottom: 10px;
                                padding-top: 10px;
                              }
                              header {
                                background-color: white;
                                border-top: 1px solid #e3e3e4;
                                border-bottom: 1px solid #e3e3e4;
                                -webkit-box-shadow: 0 2px 4px 0 #0000001a;
                                box-shadow: 0 2px 4px 0 #0000001a;
                                padding-top: 10px;
                                padding-bottom: 10px;
                              }
                              .container {
                                background-color: transparent;
                              }
                              .logo {
                                height: 50px;
                                margin-top: 10px;
                              }
                              .topbar {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 0.625rem 0;
                                font-size: 0.875rem;
                                > .container,
                                > .container-fluid {
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                }
                                .topbar-text,
                                .topbar-link {
                                  display: inline-block;
                                  margin-bottom: 0;
                                  text-decoration: none !important;
                                  > i {
                                    margin-right: 0.375rem;
                                    font-size: 1.15em;
                                    vertical-align: middle;
                                  }
                                }

                                // Carousel inside topbar
                                .cz-carousel {
                                  [data-controls] {
                                    margin-top: -1rem;
                                    width: 2rem;
                                    height: 2rem;
                                    transition: color 0.2s ease-in-out;
                                    border: 0;
                                    background: 0;
                                    font-size: 0.9558823529rem;
                                    line-height: 2rem;
                                  }
                                  [data-controls='prev'] {
                                    left: -2.2rem;
                                  }
                                  [data-controls='next'] {
                                    right: -2.2rem;
                                  }
                                }
                              }
                              @-moz-document url-prefix() {
                                .topbar .cz-carousel [data-controls] {
                                  margin-top: -0.875rem;
                                }
                              }
                              .cz-carousel {
                                [data-controls] {
                                  margin-top: -1rem;
                                  width: 2rem;
                                  height: 2rem;
                                  transition: color 0.2s ease-in-out;
                                  border: 0;
                                  background: 0;
                                  font-size: 0.9558823529rem;
                                  line-height: 2rem;
                                }
                                [data-controls='prev'] {
                                  left: -2.2rem;
                                }
                                [data-controls='next'] {
                                  right: -2.2rem;
                                }
                              }
                            }
                            @-moz-document url-prefix() {
                              .topbar .cz-carousel [data-controls] {
                                margin-top: -0.875rem;
                              }
                            }
                            .cz-carousel {
                              [data-controls] {
                                margin-top: -1rem;
                                width: 2rem;
                                height: 2rem;
                                transition: color 0.2s ease-in-out;
                                border: 0;
                                background: 0;
                                font-size: 0.9558823529rem;
                                line-height: 2rem;
                              }
                              [data-controls='prev'] {
                                left: -2.2rem;
                              }
                              [data-controls='next'] {
                                right: -2.2rem;
                              }
                            }
                          }
                          @-moz-document url-prefix() {
                            .topbar .cz-carousel [data-controls] {
                              margin-top: -0.875rem;
                            }
                          }
                          .cz-carousel {
                            [data-controls] {
                              margin-top: -1rem;
                              width: 2rem;
                              height: 2rem;
                              transition: color 0.2s ease-in-out;
                              border: 0;
                              background: 0;
                              font-size: 0.9558823529rem;
                              line-height: 2rem;
                            }
                            [data-controls='prev'] {
                              left: -2.2rem;
                            }
                            [data-controls='next'] {
                              right: -2.2rem;
                            }
                          }
                        }
                        @-moz-document url-prefix() {
                          .topbar .cz-carousel [data-controls] {
                            margin-top: -0.875rem;
                          }
                        }
                        .cz-carousel {
                          [data-controls] {
                            margin-top: -1rem;
                            width: 2rem;
                            height: 2rem;
                            transition: color 0.2s ease-in-out;
                            border: 0;
                            background: 0;
                            font-size: 0.9558823529rem;
                            line-height: 2rem;
                          }
                          [data-controls='prev'] {
                            left: -2.2rem;
                          }
                          [data-controls='next'] {
                            right: -2.2rem;
                          }
                        }
                      }
                      @-moz-document url-prefix() {
                        .topbar .cz-carousel [data-controls] {
                          margin-top: -0.875rem;
                        }
                      }
                      .cz-carousel {
                        [data-controls] {
                          margin-top: -1rem;
                          width: 2rem;
                          height: 2rem;
                          transition: color 0.2s ease-in-out;
                          border: 0;
                          background: 0;
                          font-size: 0.9558823529rem;
                          line-height: 2rem;
                        }
                        [data-controls='prev'] {
                          left: -2.2rem;
                        }
                        [data-controls='next'] {
                          right: -2.2rem;
                        }
                      }
                    }
                    @-moz-document url-prefix() {
                      .topbar .cz-carousel [data-controls] {
                        margin-top: -0.875rem;
                      }
                    }
                    .cz-carousel {
                      [data-controls] {
                        margin-top: -1rem;
                        width: 2rem;
                        height: 2rem;
                        transition: color 0.2s ease-in-out;
                        border: 0;
                        background: 0;
                        font-size: 0.9558823529rem;
                        line-height: 2rem;
                      }
                      [data-controls='prev'] {
                        left: -2.2rem;
                      }
                      [data-controls='next'] {
                        right: -2.2rem;
                      }
                    }
                  }
                  @-moz-document url-prefix() {
                    .topbar .cz-carousel [data-controls] {
                      margin-top: -0.875rem;
                    }
                  }
                  .cz-carousel {
                    [data-controls] {
                      margin-top: -1rem;
                      width: 2rem;
                      height: 2rem;
                      transition: color 0.2s ease-in-out;
                      border: 0;
                      background: 0;
                      font-size: 0.9558823529rem;
                      line-height: 2rem;
                    }
                    [data-controls='prev'] {
                      left: -2.2rem;
                    }
                    [data-controls='next'] {
                      right: -2.2rem;
                    }
                  }
                }
                @-moz-document url-prefix() {
                  .topbar .cz-carousel [data-controls] {
                    margin-top: -0.875rem;
                  }
                }
                .cz-carousel {
                  [data-controls] {
                    margin-top: -1rem;
                    width: 2rem;
                    height: 2rem;
                    transition: color 0.2s ease-in-out;
                    border: 0;
                    background: 0;
                    font-size: 0.9558823529rem;
                    line-height: 2rem;
                  }
                  [data-controls='prev'] {
                    left: -2.2rem;
                  }
                  [data-controls='next'] {
                    right: -2.2rem;
                  }
                }
              }
              @-moz-document url-prefix() {
                .topbar .cz-carousel [data-controls] {
                  margin-top: -0.875rem;
                }
              }
              .cz-carousel {
                [data-controls] {
                  margin-top: -1rem;
                  width: 2rem;
                  height: 2rem;
                  transition: color 0.2s ease-in-out;
                  border: 0;
                  background: 0;
                  font-size: 0.9558823529rem;
                  line-height: 2rem;
                }
                [data-controls='prev'] {
                  left: -2.2rem;
                }
                [data-controls='next'] {
                  right: -2.2rem;
                }
              }
            }
            @-moz-document url-prefix() {
              .topbar .cz-carousel [data-controls] {
                margin-top: -0.875rem;
              }
            }
            .cz-carousel {
              [data-controls] {
                margin-top: -1rem;
                width: 2rem;
                height: 2rem;
                transition: color 0.2s ease-in-out;
                border: 0;
                background: 0;
                font-size: 0.9558823529rem;
                line-height: 2rem;
              }
              [data-controls='prev'] {
                left: -2.2rem;
              }
              [data-controls='next'] {
                right: -2.2rem;
              }
            }
          }
          @-moz-document url-prefix() {
            .topbar .cz-carousel [data-controls] {
              margin-top: -0.875rem;
            }
          }
          .cz-carousel {
            [data-controls] {
              margin-top: -1rem;
              width: 2rem;
              height: 2rem;
              transition: color 0.2s ease-in-out;
              border: 0;
              background: 0;
              font-size: 0.9558823529rem;
              line-height: 2rem;
            }
            [data-controls='prev'] {
              left: -2.2rem;
            }
            [data-controls='next'] {
              right: -2.2rem;
            }
          }
        }
        @-moz-document url-prefix() {
          .topbar .cz-carousel [data-controls] {
            margin-top: -0.875rem;
          }
        }
        .cz-carousel {
          [data-controls] {
            margin-top: -1rem;
            width: 2rem;
            height: 2rem;
            transition: color 0.2s ease-in-out;
            border: 0;
            background: 0;
            font-size: 0.9558823529rem;
            line-height: 2rem;
          }
          [data-controls='prev'] {
            left: -2.2rem;
          }
          [data-controls='next'] {
            right: -2.2rem;
          }
        }
      }
      @-moz-document url-prefix() {
        .topbar .cz-carousel [data-controls] {
          margin-top: -0.875rem;
        }
      }
      .cz-carousel {
        [data-controls] {
          margin-top: -1rem;
          width: 2rem;
          height: 2rem;
          transition: color 0.2s ease-in-out;
          border: 0;
          background: 0;
          font-size: 0.9558823529rem;
          line-height: 2rem;
        }
        [data-controls='prev'] {
          left: -2.2rem;
        }
        [data-controls='next'] {
          right: -2.2rem;
        }
      }
    }
    @-moz-document url-prefix() {
      .topbar .cz-carousel [data-controls] {
        margin-top: -0.875rem;
      }
    }
    .cz-carousel {
      [data-controls] {
        margin-top: -1rem;
        width: 2rem;
        height: 2rem;
        transition: color 0.2s ease-in-out;
        border: 0;
        background: 0;
        font-size: 0.9558823529rem;
        line-height: 2rem;
      }
      [data-controls='prev'] {
        left: -2.2rem;
      }
      [data-controls='next'] {
        right: -2.2rem;
      }
    }
  }
  @-moz-document url-prefix() {
    .topbar .cz-carousel [data-controls] {
      margin-top: -0.875rem;
    }
  }
  .cz-carousel {
    [data-controls] {
      margin-top: -1rem;
      width: 2rem;
      height: 2rem;
      transition: color 0.2s ease-in-out;
      border: 0;
      background: 0;
      font-size: 0.9558823529rem;
      line-height: 2rem;
    }
    [data-controls='prev'] {
      left: -2.2rem;
    }
    [data-controls='next'] {
      right: -2.2rem;
    }
  }
}
@-moz-document url-prefix() {
  .topbar .cz-carousel [data-controls] {
    margin-top: -0.875rem;
  }
}
</style>
