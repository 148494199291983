import Vue from 'vue'
import store from './state/store'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import VueClazyLoad from 'vue-clazy-load'
import BreadCrumbs from '@/components/header/TheBreadcrumbs'
import VueMeta from 'vue-meta'

import onlyInt from 'vue-input-only-number'
import { ModalPlugin } from 'bootstrap-vue'
import i18n from './locales/index'

import { library } from '@fortawesome/fontawesome-svg-core'
import Toasted from 'vue-toasted'

import { faUserSecret } from '@fortawesome/free-solid-svg-icons/faUserSecret'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faThList } from '@fortawesome/free-solid-svg-icons/faThList'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit'
import { faTh } from '@fortawesome/free-solid-svg-icons/faTh'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons/faSearchPlus'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit'
import { faUserShield } from '@fortawesome/free-solid-svg-icons/faUserShield'

import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleRight'
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard'
import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleLeft'

import { faSpinner } from '@fortawesome/pro-duotone-svg-icons/faSpinner'

import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'

import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import '@/globalComponents'

library.add(
  faTh,
  faPlus,
  faMinus,
  faThList,
  faCreditCard,
  faSignOutAlt,
  faSearchPlus,
  faUserCircle,
  faUserSecret,
  faInfoCircle,
  faShoppingCart,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFacebookSquare,
  faEnvelope,
  faTwitterSquare,
  faSave,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faTimesCircle,
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faUserEdit,
  faYoutube,
  faFilePdf,
  faSpinner,
  faTrash,
  faPlus,
  faTimes,
  faDownload,
  faEdit,
  faUserShield
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('bread-crumbs', BreadCrumbs)

Vue.use(require('vue-moment'))
Vue.use(ModalPlugin)
Vue.use(VueClazyLoad)
Vue.use(VueMeta)
Vue.use(onlyInt)

Vue.use(Toasted, {
  position: 'bottom-right',
  duration: 5000
})

window.VueCalendarLang = function() {
  return {
    daysOfWeek: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
    limit: 'Limit reached ({{limit}} items max).',
    loading: 'Laden...',
    minLength: 'Min. Lengte',
    months: [
      'Januari',
      'Februari',
      'Maart',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Augustus',
      'September',
      'October',
      'November',
      'December'
    ],
    notSelected: 'Niets geselecteerd',
    required: 'Vereist',
    search: 'Zoeken'
  }
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
